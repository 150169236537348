import React from 'react';
import classNames from 'classnames/bind';
import moment from 'moment';

import styles from './ContactDetailsSidebarContent.scss';
import { useTranslation } from 'react-i18next';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import { CompanyContactDetailsT } from 'common/store/members/models';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import FieldValue from 'common/components/FieldValue/FieldValue';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import { useDispatch, useSelector } from 'react-redux';
import { selectCountriesByCode, selectCountriesByLangCode } from 'common/store/countries-dict/selectors';
import PhoneNumberIcon from 'common/components/PhoneNumberIcon/PhoneNumberIcon';
import { ICON_BY_MEDIA } from 'common/layouts/Members/media-icons';
import { SocialMediaEnum } from 'common/constants';
import { isNonNil } from 'common/utils';
import chunk from 'lodash/chunk';
import { FORMAL_T_MAP } from 'common/components/dropdowns/UserFormalDropdown/UserFormalDropdown';
import { logWarning } from 'common/utils/logger';
import { ContactPositionsEnum, CountryCodeT } from 'common/utils/api/models';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import PositionOption from 'common/layouts/Members/PositionOption/PositionOption';
import { CommonSidebarDataT, CommonSidebarsTypeEnum, SidebarContentPropsT } from 'common/layouts/SideBars/models';
import { ContactDetailsSidebarDataT } from 'common/layouts/SideBars/contents/ContactDetailsSidebarContent/models';
import {
    selectContactDetailsById,
    selectContactIdByUserId,
    selectDeleteMemberRequest,
    selectFetchContactDetailsRequest,
    selectUpdateMemberRequest,
} from 'common/store/members/selectors';
import { selectPermissions } from 'common/store/auth/selectors';
import ContactSidebarHeader from 'common/layouts/Members/ContactSidebarHeader/ContactSidebarHeader';
import {
    changeAdminRoleWithSuccessors,
    changeUserRole,
    changeUserRoleWithSuccessor,
    deleteContact,
    deleteUser,
    deleteUserWithSuccessor,
    fetchContactDetails,
    fetchContactDetailsByUserId,
    restoreUser,
} from 'common/store/members/actions';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import ChangeUserRoleModal, {
    ChangeUserRoleModalDataT,
    ChangeUserRoleModalPropsT,
} from 'common/layouts/Members/dialogs/ChangeUserRoleModal/ChangeUserRoleModal';
import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';
import DeactivateUserModal, {
    DeactivateUserModalDataT,
    DeactivateUserModalPropsT,
} from 'common/layouts/Members/dialogs/DeactivateUserModal/DeactivateUserModal';
import DeleteContactConfirmation, {
    DeleteContactConfirmationDataT,
    DeleteContactConfirmationPropsT,
} from 'common/layouts/Members/dialogs/DeleteContactConfirmation/DeleteContactConfirmation';
import { useActionOptions } from 'common/layouts/SideBars/contents/ContactDetailsSidebarContent/hooks/use-action-options';
import RestoreUserConfirmation, {
    RestoreUserConfirmationDataT,
    RestoreUserConfirmationPropsT,
} from 'common/layouts/Members/dialogs/RestoreUserConfirmation/RestoreUserConfirmation';
import ChangeUserRoleConfirmation, {
    ChangeUserRoleConfirmationDataT,
    ChangeUserRoleConfirmationPropsT,
} from 'common/layouts/Members/dialogs/ChangeUserRoleConfirmation/ChangeUserRoleConfirmation';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';

const cx = classNames.bind(styles);

type PropsT = SidebarContentPropsT<ContactDetailsSidebarDataT, CommonSidebarDataT>;

const formatBirthday = (birthday: string) => moment(birthday, 'YYYY-MM-DD').format('DD.MM.YYYY');

const ContactDetailsSidebarContent: React.FC<PropsT> = (props) => {
    const { data, onClose, onGoBack, onOpenNextSidebar } = props;

    const { partnerId, partnerType } = data;

    const { t } = useTranslation();

    const permissions = useSelector(selectPermissions);

    const contactDetailsRequestStatus = useSelector(selectFetchContactDetailsRequest(partnerId));
    const isLoading = contactDetailsRequestStatus.loading;

    const contactDetailsById = useSelector(selectContactDetailsById(partnerId));
    const contactIdByUserId = useSelector(selectContactIdByUserId(partnerId));

    let contactDetails: CompanyContactDetailsT | null = null;

    if ('contactId' in data) {
        contactDetails = contactDetailsById[data.contactId] || null;
    }

    if ('userId' in data) {
        const contactId = contactIdByUserId[data.userId];
        contactDetails = contactDetailsById[contactId] || null;
    }

    const handleEditContact = () => {
        if (!contactDetails?.id) {
            logWarning('failed to open edit contact, empty contactDetails?.id');
            return;
        }

        if (!('partnerId' in data)) {
            logWarning('failed to open edit contact, empty data.partnerId');
            return;
        }

        onOpenNextSidebar({
            contactId: contactDetails.id,
            partnerId: data.partnerId,
            type: CommonSidebarsTypeEnum.editContact,
        });
    };

    const deleteMemberRequestStatus = useSelector(selectDeleteMemberRequest(partnerId));
    const updateMemberRequestStatus = useSelector(selectUpdateMemberRequest(partnerId));

    const dispatch = useDispatch();

    React.useEffect(() => {
        if ('contactId' in data) {
            dispatch(fetchContactDetails(data.contactId, partnerId));
        }
        if ('userId' in data) {
            dispatch(fetchContactDetailsByUserId(data.userId, partnerId));
        }
    }, []);

    const changeUserRoleModalDialog = useModalDialog<ChangeUserRoleModalDataT>();
    const handleShowChangeUserRoleModalDialog = React.useCallback(
        (contact: CompanyContactDetailsT): void => {
            changeUserRoleModalDialog.setData({
                partnerType,
                partnerId,
                contact: {
                    userId: contact.userId,
                    roles: contact.roles,
                    fullName: contact.fullName,
                },
            });
        },
        [partnerType, partnerId],
    );

    const changeUserRoleConfirmationDialog = useModalDialog<ChangeUserRoleConfirmationDataT>();
    const handleChangeUserRole: ChangeUserRoleModalPropsT['onChangeUserRole'] = React.useCallback(
        ({ data, changes }): void => {
            if (!data?.partnerId) {
                return;
            }

            changeUserRoleModalDialog.onClose();
            changeUserRoleConfirmationDialog.setData({
                ...data,
                changes,
                isNeedSuccessor: false,
            });
        },
        [],
    );

    const onChangeUserRoleWithSuccessor: ChangeUserRoleModalPropsT['onChangeUserRoleWithSuccessor'] = React.useCallback(
        ({ data, changes }): void => {
            if (!data?.partnerId) {
                return;
            }

            changeUserRoleModalDialog.onClose();
            changeUserRoleConfirmationDialog.setData({
                ...data,
                changes,
                isNeedSuccessor: true,
            });
        },
        [],
    );

    const onChangeAdminRole: ChangeUserRoleModalPropsT['onChangeAdminRole'] = React.useCallback(({ changes }): void => {
        dispatch(
            changeAdminRoleWithSuccessors(
                changes.role,
                changes.userId,
                changes.newDispatcherUserId,
                changes.newKeyAccountManagerUserId,
                partnerId,
            ),
        );
    }, []);

    const handleConfirmChangeUserRole: ChangeUserRoleConfirmationPropsT['onConfirm'] = React.useCallback(
        ({ partnerId, isNeedSuccessor, changes }) => {
            if (isNeedSuccessor) {
                if (!changes.successorUserId || !changes.successorRole) {
                    return;
                }

                dispatch(
                    changeUserRoleWithSuccessor(
                        changes.role,
                        changes.userId,
                        changes.successorUserId,
                        changes.successorRole,
                        partnerId,
                    ),
                );
            } else {
                dispatch(changeUserRole(changes.role, changes.userId, partnerId));
            }
        },
        [],
    );

    const deactivateUserModalDialog = useModalDialog<DeactivateUserModalDataT>();
    const handleShowDeactivateUserModalDialog = React.useCallback(
        (contact: CompanyContactDetailsT): void => {
            deactivateUserModalDialog.setData({
                partnerType,
                partnerId,
                contact: {
                    userId: contact.userId,
                    roles: contact.roles,
                    fullName: contact.fullName,
                },
            });
        },
        [partnerType, partnerId],
    );

    const handleDeactivateUser: DeactivateUserModalPropsT['onDeactivateUser'] = React.useCallback(
        ({ data, isNeedSuccessor, changes }): void => {
            if (!data?.partnerId) {
                return;
            }

            if (isNeedSuccessor) {
                if (!changes.successorUserId || !changes.successorRole) {
                    return;
                }

                dispatch(
                    deleteUserWithSuccessor(
                        changes.userId,
                        changes.successorUserId,
                        changes.successorRole,
                        data.partnerId,
                    ),
                );
            } else {
                dispatch(deleteUser(changes.userId, data.partnerId));
            }
        },
        [],
    );

    const deleteContactConfirmation = useModalDialog<DeleteContactConfirmationDataT>();
    const handleDeleteContactModalDialog = (contact: CompanyContactDetailsT) => {
        if (!contact?.id) {
            return;
        }

        deleteContactConfirmation.setData({
            contactId: contact.id,
            partnerId,
        });
    };
    const handleConfirmDeleteContact: DeleteContactConfirmationPropsT['onConfirm'] = React.useCallback(
        (partnerId, contactId) => {
            if (!contactId) {
                logWarning('empty contactId');
                return;
            }

            dispatch(deleteContact(contactId, partnerId));
        },
        [],
    );

    const restoreUserConfirmationDialog = useModalDialog<RestoreUserConfirmationDataT>();
    const handleShowRestoreUserModalDialog = (contact: CompanyContactDetailsT) => {
        if (!contact.id) {
            return;
        }

        restoreUserConfirmationDialog.setData({
            contactId: contact.id,
            partnerId,
        });
    };
    const handleConfirmRestoreUser: RestoreUserConfirmationPropsT['onConfirm'] = React.useCallback(
        (userId, partnerId): void => {
            dispatch(restoreUser(userId, partnerId));
        },
        [partnerId],
    );

    useCloseModalDialogAfterRequest(updateMemberRequestStatus, [
        changeUserRoleModalDialog,
        changeUserRoleConfirmationDialog,
        deactivateUserModalDialog,
        restoreUserConfirmationDialog,
    ]);
    useCloseModalDialogAfterRequest(deleteMemberRequestStatus, [deleteContactConfirmation]);

    const headerActionOptions = useActionOptions(t, permissions, contactDetails, {
        onChangeUserRole: handleShowChangeUserRoleModalDialog,
        onDeactivateUser: handleShowDeactivateUserModalDialog,
        onDeleteContact: handleDeleteContactModalDialog,
        onEditContact: handleEditContact,
        onRestoreUser: handleShowRestoreUserModalDialog,
    });

    const countryByCode = useSelector(selectCountriesByCode);
    const countryByLangCode = useSelector(selectCountriesByLangCode);

    const mediasNodes = [
        <FieldValue
            key="email"
            className={cx('field--contact')}
            label={t('common:team-members.edit-contact.fields.email.label')}
            value={contactDetails?.email || ''}
        />,
        <FieldValue
            key="mobile-phone"
            icon={<PhoneNumberIcon phoneNumber={contactDetails?.mobilePhone} />}
            className={cx('field--contact')}
            label={t('common:team-members.edit-contact.fields.mobile-phone.label')}
            value={contactDetails?.mobilePhone || ''}
        />,
        <FieldValue
            key="contact"
            icon={<PhoneNumberIcon phoneNumber={contactDetails?.deskPhone} />}
            className={cx('field--contact')}
            label={t('common:team-members.edit-contact.fields.desktop-phone.label')}
            value={contactDetails?.deskPhone || ''}
        />,
        contactDetails?.fax ? (
            <FieldValue
                key="fax"
                className={cx('field--contact')}
                label={t('common:team-members.edit-contact.fields.fax-number.label')}
                value={contactDetails?.fax || ''}
            />
        ) : null,
        <FieldValue
            key="link"
            className={cx('field--link')}
            label={t('common:team-members.edit-contact.fields.webpage-link.label')}
            value={contactDetails?.website || ''}
        />,
        ...(contactDetails?.medias || []).map((media, index) => {
            if (typeof media.login !== 'string') {
                return null;
            }

            const icon = ICON_BY_MEDIA[media.type as SocialMediaEnum];

            return (
                <FieldValue
                    key={index}
                    icon={icon}
                    className={cx('field--contact')}
                    label={t(`common:team-members.edit-contact.fields.${media.type}.label`)}
                    value={media.login || ''}
                />
            );
        }),
    ].filter(isNonNil);

    const mediaChunks = chunk(mediasNodes, 2);

    return (
        <>
            {isLoading && <LoaderOverlay />}
            <HeaderSideBarLayout>
                <HeaderSideBarContent
                    onGoBack={onGoBack}
                    title={contactDetails ? <ContactSidebarHeader contactDetails={contactDetails} /> : null}
                    dropdownOptions={headerActionOptions}
                    onClose={onClose}
                />
            </HeaderSideBarLayout>
            <SideBarLayout>
                <div className={cx('section')}>
                    <div className={cx('section__title')}>{t('common:team-members.details.sections.name')}</div>
                    <FieldGroup>
                        <FieldValue
                            className={cx('field--formal')}
                            label={t('common:team-members.edit-contact.fields.formal.label')}
                            value={contactDetails?.formal ? t(FORMAL_T_MAP[contactDetails?.formal]) : ''}
                            placeholder={t('common:team-members.edit-contact.fields.formal.placeholder')}
                        />
                        <FieldValue
                            className={cx('field--first-name')}
                            label={t('common:team-members.edit-contact.fields.first-name.label')}
                            value={contactDetails?.name || ''}
                        />
                        <FieldValue
                            className={cx('field--last-name')}
                            label={t('common:team-members.edit-contact.fields.last-name.label')}
                            value={contactDetails?.surname || ''}
                        />
                    </FieldGroup>
                </div>
                <div className={cx('section')}>
                    <div className={cx('section__title')}>
                        {t('common:team-members.edit-contact.sections.position')}
                    </div>
                    <div className={cx('section__block')}>
                        <FieldGroup>
                            {!!contactDetails?.position && (
                                <PositionOption
                                    isDisabled
                                    value={contactDetails.position as ContactPositionsEnum}
                                    selectedValue={contactDetails.position as ContactPositionsEnum}
                                />
                            )}
                            {!!contactDetails && (
                                <Checkbox
                                    isDisabled
                                    checked={!!contactDetails?.main}
                                    label={
                                        <span className={cx('checkbox__label')}>
                                            {t('common:team-members.edit-contact.fields.default.label')}
                                        </span>
                                    }
                                />
                            )}
                        </FieldGroup>
                    </div>
                </div>
                <div className={cx('section')}>
                    <div className={cx('section__title')}>{t('common:team-members.details.sections.contacts')}</div>
                    {mediaChunks.map((chunk, chunkIndex) => (
                        <FieldGroup key={chunkIndex}>{chunk}</FieldGroup>
                    ))}
                </div>
                <div className={cx('section', 'section--isLast')}>
                    <div className={cx('section__title')}>{t('common:team-members.details.sections.personals')}</div>
                    <FieldGroup>
                        <FieldValue
                            className={cx('field--birthday')}
                            label={t('common:team-members.edit-contact.fields.birthday.label')}
                            value={contactDetails?.birthday ? formatBirthday(contactDetails?.birthday) : ''}
                            placeholder={t('common:team-members.edit-contact.fields.birthday.placeholder')}
                        />
                        <FieldValue
                            className={cx('field--country')}
                            label={t('common:team-members.edit-contact.fields.residence.label')}
                            icon={<FlagIcon countryCode={contactDetails?.residence} />}
                            value={
                                countryByCode[contactDetails?.residence as CountryCodeT]?.userLangDisplayName || null
                            }
                            placeholder={t('common:fields.placeholder.not-specified')}
                        />
                    </FieldGroup>
                    <FieldValue
                        className={cx('field--language')}
                        label={t('common:team-members.edit-contact.fields.language.label')}
                        value={countryByLangCode[contactDetails?.language as string]?.name || null}
                        placeholder={t('common:fields.placeholder.not-specified')}
                    />
                </div>
            </SideBarLayout>
            {deactivateUserModalDialog.data && (
                <DeactivateUserModal
                    data={deactivateUserModalDialog.data}
                    onClose={deactivateUserModalDialog.onClose}
                    onCancel={deactivateUserModalDialog.onCancel}
                    onDeactivateUser={handleDeactivateUser}
                />
            )}
            {changeUserRoleModalDialog.data && (
                <ChangeUserRoleModal
                    data={changeUserRoleModalDialog.data}
                    onClose={changeUserRoleModalDialog.onClose}
                    onChangeUserRole={handleChangeUserRole}
                    onChangeUserRoleWithSuccessor={onChangeUserRoleWithSuccessor}
                    onChangeAdminRole={onChangeAdminRole}
                />
            )}
            <ChangeUserRoleConfirmation
                data={changeUserRoleConfirmationDialog.data}
                onClose={changeUserRoleConfirmationDialog.onClose}
                onConfirm={handleConfirmChangeUserRole}
            />
            {restoreUserConfirmationDialog.data && (
                <RestoreUserConfirmation
                    data={restoreUserConfirmationDialog.data}
                    onClose={restoreUserConfirmationDialog.onClose}
                    onConfirm={handleConfirmRestoreUser}
                />
            )}
            {deleteContactConfirmation.data && (
                <DeleteContactConfirmation
                    data={deleteContactConfirmation.data}
                    onClose={deleteContactConfirmation.onClose}
                    onCancel={deleteContactConfirmation.onCancel}
                    onConfirm={handleConfirmDeleteContact}
                />
            )}
        </>
    );
};

export default ContactDetailsSidebarContent;
