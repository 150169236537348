import * as React from 'react';
import { useMemo } from 'react';

import classNames from 'classnames/bind';

import styles from './TransportOrderDetails.scss';
import InfoTable, { InfoTableRowT } from 'design-system/components/InfoTable/InfoTable';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import { useTranslation } from 'react-i18next';
import OrderRouteLocations from 'common/components/order-details/OrderRouteLocations/OrderRouteLocations';
import { TransportOrderDetailsT } from 'common/store/transport-order-details/models';
import { isNonNil } from 'common/utils';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import ExcludedCountries from 'common/components/ExcludedCountries/ExcludedCountries';
import RouteIcon from 'common/icons/RouteIcon';
import TeamDrivePill from 'common/components/status-pill/TeamDrivePill/TeamDrivePill';
import TrailerIcon from 'common/icons/TrailerIcon';
import EmissionIcon from 'common/icons/EmissionIcon';
import TruckIcon from 'common/icons/TruckIcon';
import DriverIcon from 'common/icons/DriverIcon';
import EuroSymbolIcon from 'common/icons/EuroSymbolIcon';
import { ShipmentStatusEnum } from 'common/utils/api/models';
import TourPapersCard from 'common/components/TourPapersCard/TourPapersCard';
import AssetLinkFormatter from 'design-system/components/InfoTable/formatters/AssetLinkFormatter/AssetLinkFormatter';
import AssetLabelFormatter from 'design-system/components/InfoTable/formatters/AssetLabelFormatter/AssetLabelFormatter';
import Alert, { AlertSizeEnum, AlertThemeEnum } from 'common/components/Alert/Alert';
import PriceDetails, { PriceDetailT } from 'design-system/components/PriceDetails/PriceDetails';
import isNumber from 'lodash/isNumber';
import AsyncAdditionalServiceFormatter from 'design-system/components/InfoTable/formatters/AsyncAdditionalServiceFormatter/AsyncAdditionalServiceFormatter';
import { useCommonPriceDetails } from 'common/components/PriceDetails/hook';
import CaseIcon from 'common/icons/CaseIcon';
import DateFormatter from 'design-system/components/InfoTable/formatters/DateFormatter/DateFormatter';
import Link, { LinkThemeEnum } from 'common/components/Link/Link';
import { convertToKm } from 'common/utils/distance';
import SimpleDriverFormatter from 'design-system/components/InfoTable/formatters/SimpleDriverFormatter/SimpleDriverFormatter';
import TeamDrive2Icon from 'common/icons/TeamDrive2Icon';
import { logWarning } from 'common/utils/logger';
import ShipmentDetailsCard from 'common/layouts/TransportOrderDetailsPageLayout/TransportOrderDetails/ShipmentDetailsCard/ShipmentDetailsCard';
import RouteDetailsCard from 'common/layouts/TransportOrderDetailsPageLayout/TransportOrderDetails/RouteDetailsCard/RouteDetailsCard';
import AsyncTrailerTypeFormatter from 'design-system/components/InfoTable/formatters/AsyncTrailerTypeFormatter/AsyncTrailerTypeFormatter';
import TransportOrderTypeFormatter from 'design-system/components/InfoTable/formatters/TransportOrderTypeFormatter/TransportOrderTypeFormatter';
import EmissionClassFormatter from 'design-system/components/InfoTable/formatters/EmissionClassFormatter/EmissionClassFormatter';
import keyBy from 'lodash/keyBy';
import DriverLinkFormatter from 'design-system/components/InfoTable/formatters/DriverLinkFormatter/DriverLinkFormatter';
import { useTransportOrderPayloadWaypoints } from 'carrier/utils/hooks/useTransportOrderPayloadWaypoints';
import { checkShouldShowLayover } from 'common/components/PriceDetails/utils';
import TimeWindowIcon from 'common/icons/TimeWindowIcon';
import WarningIcon from 'common/icons/WarningIcon';
import SnowflakeIcon from 'common/icons/SnowflakeIcon';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { formatISOTime } from 'common/utils/time';
import max from 'lodash/max';
import min from 'lodash/min';

type PropsT = {
    isBroker: boolean;
    isReadOnly: boolean;
    transportOrderDetails: TransportOrderDetailsT | null | undefined;
    goToTrailerDetails: (trailerId: TrailerIdT | null) => void;
    goToTruckDetails: (truckId: TruckIdT | null) => void;
    goToDriverDetails?: (driverId: DriverIdT | null) => void;
    goToSpotRequestDetails: (spotRequestId: SpotRequestIdT | null) => void;
};

const cx = classNames.bind(styles);

const TransportOrderDetails: React.FC<PropsT> = React.memo((props) => {
    const {
        isBroker,
        isReadOnly,
        transportOrderDetails,
        goToTrailerDetails,
        goToTruckDetails,
        goToDriverDetails,
        goToSpotRequestDetails,
    } = props;

    const { t } = useTranslation();

    const waypointById = keyBy(transportOrderDetails?.waypoints, 'id');

    const { firstPayloadWaypoint, lastPayloadWaypoint } = useTransportOrderPayloadWaypoints(
        transportOrderDetails?.waypoints,
    );

    const commonPriceDetails = useCommonPriceDetails();

    const summaryAdditionalServicesCost = useMemo((): number => {
        return (
            transportOrderDetails?.additionalServiceCosts?.reduce<number>((acc, additionalServiceCost) => {
                return acc + (additionalServiceCost?.cost || 0);
            }, 0) || 0
        );
    }, [transportOrderDetails?.additionalServiceCosts]);

    const priceDetailList: Array<PriceDetailT | null> = [
        {
            iconNode: <EuroSymbolIcon strokeColor={StyleGuideColorsEnum.gray} />,
            title: t('common:transport-order-details.columns.price'),
            price: transportOrderDetails?.carrierCost?.totalCost,
            list: [
                {
                    ...commonPriceDetails.lineHaul,
                    price: transportOrderDetails?.carrierCost?.lineHaul,
                    list: commonPriceDetails.getLineHaulList({
                        deadheadLineHaulCost: transportOrderDetails?.deadheadLinehaulCost,
                        payloadLineHaulCost: transportOrderDetails?.payloadLinehaulCost,
                    }),
                },
                {
                    ...commonPriceDetails.getRoadTaxes(transportOrderDetails?.carrierCost?.co2),
                    price: transportOrderDetails?.carrierCost?.tollCost,
                    list: commonPriceDetails.getRoadTaxesList(transportOrderDetails?.carrierCost?.tollByRoadType),
                },
                {
                    ...commonPriceDetails.fuelCost,
                    price: transportOrderDetails?.carrierCost?.fuelCost,
                },
                {
                    ...commonPriceDetails.additionalServices,
                    price: summaryAdditionalServicesCost,
                    list: transportOrderDetails?.additionalServiceCosts?.length
                        ? [
                              {
                                  list: (transportOrderDetails?.additionalServiceCosts || []).map(
                                      (additionalServiceCost): PriceDetailT | null => {
                                          return {
                                              iconNode: null,
                                              title: (
                                                  <AsyncAdditionalServiceFormatter type={additionalServiceCost.type} />
                                              ),
                                              price: additionalServiceCost.cost,
                                          };
                                      },
                                  ),
                              },
                          ]
                        : undefined,
                },
                checkShouldShowLayover(
                    transportOrderDetails?.carrierCost?.layoverSeconds,
                    transportOrderDetails?.carrierCost?.layoverCost,
                )
                    ? {
                          ...commonPriceDetails.getLayoverCost(transportOrderDetails?.carrierCost?.layoverSeconds),
                          price: transportOrderDetails?.carrierCost?.layoverCost || 0,
                      }
                    : null,
            ],
        },
    ].filter(isNonNil);

    const excludedCountries = transportOrderDetails?.prohibitedCountries || [];

    const transportOrderRows: Array<InfoTableRowT | null> = [
        {
            icon: (
                <CaseIcon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={StyleGuideColorsEnum.gray}
                    fillColor={StyleGuideColorsEnum.light}
                />
            ),
            name: t('common:transport-order-details.columns.transport-order-number'),
            value: transportOrderDetails?.number,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'transport-order',
        },
        {
            icon: null,
            name: t('common:transport-order-details.columns.created-date'),
            value: <DateFormatter date={transportOrderDetails?.createdDate} format="DD MMM YYYY, HH:mm" />,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'created-date',
        },
        transportOrderDetails?.spotInfo?.id
            ? {
                  icon: null,
                  name: t('common:transport-order-details.columns.spot-request'),
                  value: (
                      <Link
                          onClick={() => {
                              const spotRequestId = transportOrderDetails?.spotInfo?.id || null;
                              if (!spotRequestId) {
                                  logWarning('Empty spotRequestId');
                                  return;
                              }

                              goToSpotRequestDetails(spotRequestId);
                          }}
                          className={cx('link')}
                          theme={LinkThemeEnum.boldBrandDark}
                      >
                          {transportOrderDetails?.spotInfo?.number}
                      </Link>
                  ),
                  openedClassName: cx('spot-request-row'),
                  emptyValue: t('common:info-table.placeholders.not-specified'),
                  isBoldValue: true,
                  testSelector: 'spot-request',
              }
            : null,
        {
            icon: null,
            name: t('common:transport-order-details.columns.provide'),
            value: <TransportOrderTypeFormatter transportOrderType={transportOrderDetails?.type} />,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            rightNode: transportOrderDetails?.tourInfo?.teamDrive ? <TeamDrivePill /> : null,
            testSelector: 'provide',
        },
        {
            icon: (
                <TrailerIcon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={StyleGuideColorsEnum.gray}
                    fillColor={StyleGuideColorsEnum.light}
                />
            ),
            name: t('common:transport-order-details.columns.trailer-type'),
            value: <AsyncTrailerTypeFormatter trailerDictId={transportOrderDetails?.trailerInfo?.id} />,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'trailer-type',
        },
        {
            icon: <EmissionIcon strokeColor={StyleGuideColorsEnum.gray} />,
            name: t('common:transport-order-details.columns.emission-class'),
            value: <EmissionClassFormatter emissionClass={transportOrderDetails?.emissionClass} />,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'emissions-class',
        },
    ];

    const totalDistance = Math.round(transportOrderDetails?.totalDistance || 0);

    const payloadDistance = Math.round(transportOrderDetails?.payloadDistance || 0);

    const summaryDeadheadDistance = totalDistance - payloadDistance;

    const mileageDetailsRows: Array<InfoTableRowT | null> = [
        {
            icon: <RouteIcon fillColor={StyleGuideColorsEnum.gray} />,
            name: t('common:transport-order-details.columns.total-mileage'),
            value: <UnitTypeCount type={UnitTypeEnum.kilometersAbbreviation} count={convertToKm(totalDistance)} />,
            emptyValue: t('common:info-table.placeholders.empty'),
            isBoldValue: true,
            testSelector: 'total-mileage',
        },
        payloadDistance
            ? {
                  icon: null,
                  name: t('common:transport-order-details.columns.payload-mileage'),
                  value: (
                      <UnitTypeCount type={UnitTypeEnum.kilometersAbbreviation} count={convertToKm(payloadDistance)} />
                  ),
                  emptyValue: t('common:info-table.placeholders.empty'),
                  isBoldValue: true,
                  testSelector: 'payload-mileage',
              }
            : null,
        summaryDeadheadDistance
            ? {
                  icon: null,
                  name: t('common:transport-order-details.columns.deadhead-mileage'),
                  value: (
                      <UnitTypeCount
                          type={UnitTypeEnum.kilometersAbbreviation}
                          count={convertToKm(summaryDeadheadDistance)}
                      />
                  ),
                  emptyValue: t('common:info-table.placeholders.empty'),
                  isBoldValue: true,
                  testSelector: 'deadhead-mileage',
              }
            : null,
    ];

    const mainDriver = transportOrderDetails?.drivers?.[0] || null;
    const teamDrivers = useMemo(() => {
        const shouldShowTeamDrivers = !!transportOrderDetails?.tourInfo?.teamDrive;
        if (!shouldShowTeamDrivers) {
            return [];
        }

        const drivers = transportOrderDetails?.drivers?.slice(1) || [];
        return drivers?.length ? drivers : [null];
    }, [transportOrderDetails]);

    const assignmentDetailsRows: Array<InfoTableRowT | null> = [
        {
            icon: (
                <TruckIcon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={StyleGuideColorsEnum.gray}
                    fillColor={StyleGuideColorsEnum.light}
                />
            ),
            name: t('common:transport-order-details.columns.truck'),
            value: transportOrderDetails?.truck ? (
                <AssetLinkFormatter assetId={transportOrderDetails?.truck?.id} onOpenAssetDetails={goToTruckDetails}>
                    <AssetLabelFormatter
                        model={transportOrderDetails?.truck?.dictTruckInfo?.model}
                        plateNumber={transportOrderDetails?.truck?.plateNumber}
                    />
                </AssetLinkFormatter>
            ) : null,
            emptyValue: t('common:info-table.placeholders.truck-not-assigned'),
            isBoldValue: true,
        },
        {
            icon: (
                <TrailerIcon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={StyleGuideColorsEnum.gray}
                    fillColor={StyleGuideColorsEnum.light}
                />
            ),
            name: t('common:transport-order-details.columns.trailer'),
            value: transportOrderDetails?.trailer ? (
                <AssetLinkFormatter
                    assetId={transportOrderDetails?.trailer?.id}
                    onOpenAssetDetails={goToTrailerDetails}
                >
                    <AssetLabelFormatter
                        model={transportOrderDetails?.trailer?.dictTrailerInfo?.model}
                        plateNumber={transportOrderDetails?.trailer?.plateNumber}
                    />
                </AssetLinkFormatter>
            ) : null,
            emptyValue: t('common:info-table.placeholders.trailer-not-assigned'),
            isBoldValue: true,
        },
        {
            icon: <DriverIcon size={DEFAULT_ICON_SIZE} fillColor={StyleGuideColorsEnum.gray} />,
            name: t('common:transport-order-details.columns.driver'),
            value: mainDriver ? (
                <DriverLinkFormatter driverId={mainDriver.id} onOpenDriverDetails={goToDriverDetails}>
                    <SimpleDriverFormatter driver={mainDriver} />
                </DriverLinkFormatter>
            ) : null,
            emptyValue: t('common:info-table.placeholders.driver-not-assigned'),
            isBoldValue: true,
        },
        ...teamDrivers.map((driver): InfoTableRowT => {
            return {
                icon: <TeamDrive2Icon size={DEFAULT_ICON_SIZE} strokeColor={StyleGuideColorsEnum.gray} />,
                name: t('common:transport-order-details.columns.team-driver'),
                value: driver ? (
                    <DriverLinkFormatter driverId={driver.id} onOpenDriverDetails={goToDriverDetails}>
                        <SimpleDriverFormatter driver={driver} />
                    </DriverLinkFormatter>
                ) : null,
                emptyValue: t('common:info-table.placeholders.assets-not-assigned'),
                isBoldValue: true,
            };
        }),
    ];

    const checkIsLastNotCanceledShipment = (shipmentId: ShipmentIdT): boolean => {
        return (
            transportOrderDetails?.shipments?.every((shipment) => {
                if (shipment.id === shipmentId && shipment.status !== ShipmentStatusEnum.canceled) {
                    return true;
                }

                return shipment.status === ShipmentStatusEnum.canceled;
            }) || false
        );
    };

    const reeferTemperatureMin = transportOrderDetails?.trailer?.reeferTemperatureMin || null;
    const reeferTemperatureMax = transportOrderDetails?.trailer?.reeferTemperatureMax || null;

    const requiredTemperatureMin = max(
        transportOrderDetails?.shipments?.map((shipment) => {
            return shipment?.lowestTemperature;
        }),
    );
    const requiredTemperatureMax = min(
        transportOrderDetails?.shipments?.map((shipment) => {
            return shipment?.highestTemperature;
        }),
    );

    if (!transportOrderDetails) {
        return null;
    }

    return (
        <div className={cx('container')}>
            <OrderRouteLocations
                className={cx('route')}
                origin={firstPayloadWaypoint?.address}
                pickupDockingHoursFrom={firstPayloadWaypoint?.dateTimeFrom}
                pickupDockingHoursTo={firstPayloadWaypoint?.dateTimeTo}
                destination={lastPayloadWaypoint?.address}
                dropOffDockingHoursFrom={lastPayloadWaypoint?.dateTimeFrom}
                dropOffDockingHoursTo={lastPayloadWaypoint?.dateTimeTo}
            />
            <ExcludedCountries
                className={cx('excluded-countries')}
                titleNode={t('common:order-details.excluded-countries.title')}
                countryCodes={excludedCountries}
                tooltipNode={t('common:order-details.excluded-countries.tooltip')}
            />
            {transportOrderDetails?.tourInfo?.warnings?.runningOutOfTimeToAssignAsset && (
                <Alert
                    icon={<WarningIcon fillColor={StyleGuideColorsEnum.white} />}
                    size={AlertSizeEnum.small}
                    theme={AlertThemeEnum.orange}
                    className={cx('alert')}
                >
                    {t('common:transport-order-details.alerts.running-out-of-time-assign-assets')}
                </Alert>
            )}
            {transportOrderDetails?.tourInfo?.warnings?.runOutOfTimeToAssignAsset && (
                <Alert
                    icon={<WarningIcon fillColor={StyleGuideColorsEnum.white} />}
                    size={AlertSizeEnum.small}
                    theme={AlertThemeEnum.tomatoRed}
                    className={cx('alert')}
                >
                    {t('common:transport-order-details.alerts.run-out-of-time-assign-assets')}
                </Alert>
            )}
            {transportOrderDetails?.tourInfo?.warnings?.runningOutOfTimeToAssignDriver && (
                <Alert
                    icon={<WarningIcon fillColor={StyleGuideColorsEnum.white} />}
                    size={AlertSizeEnum.small}
                    theme={AlertThemeEnum.orange}
                    className={cx('alert')}
                >
                    {t('common:transport-order-details.alerts.running-out-of-time-assign-driver')}
                </Alert>
            )}
            {transportOrderDetails?.tourInfo?.warnings?.runOutOfTimeToAssignDriver && (
                <Alert
                    icon={<WarningIcon fillColor={StyleGuideColorsEnum.white} />}
                    size={AlertSizeEnum.small}
                    theme={AlertThemeEnum.tomatoRed}
                    className={cx('alert')}
                >
                    {t('common:transport-order-details.alerts.run-out-of-time-assign-driver')}
                </Alert>
            )}
            {transportOrderDetails?.tourInfo?.warnings?.delaying && (
                <Alert
                    icon={
                        <TimeWindowIcon
                            strokeColor={StyleGuideColorsEnum.white}
                            fillColor={StyleGuideColorsEnum.transparent}
                        />
                    }
                    size={AlertSizeEnum.small}
                    theme={AlertThemeEnum.orange}
                    className={cx('alert')}
                >
                    {t('common:transport-order-details.alerts.delaying')}
                </Alert>
            )}
            {isNumber(reeferTemperatureMin) &&
                isNumber(requiredTemperatureMin) &&
                reeferTemperatureMin < requiredTemperatureMin && (
                    <Alert
                        icon={<SnowflakeIcon fillColor={StyleGuideColorsEnum.white} />}
                        size={AlertSizeEnum.small}
                        theme={AlertThemeEnum.tomatoRed}
                        className={cx('alert')}
                    >
                        {t('common:transport-order-details.alerts.less-required-temperature', {
                            current: reeferTemperatureMin,
                            required: requiredTemperatureMin,
                        })}
                    </Alert>
                )}
            {isNumber(reeferTemperatureMax) &&
                isNumber(requiredTemperatureMax) &&
                reeferTemperatureMax > requiredTemperatureMax && (
                    <Alert
                        icon={<SnowflakeIcon fillColor={StyleGuideColorsEnum.white} />}
                        size={AlertSizeEnum.small}
                        theme={AlertThemeEnum.tomatoRed}
                        className={cx('alert')}
                    >
                        {t('common:transport-order-details.alerts.more-required-temperature', {
                            current: reeferTemperatureMax,
                            required: requiredTemperatureMax,
                        })}
                    </Alert>
                )}
            {isNumber(reeferTemperatureMin) && isNumber(reeferTemperatureMax) && (
                <Alert
                    icon={<SnowflakeIcon fillColor={StyleGuideColorsEnum.white} />}
                    size={AlertSizeEnum.small}
                    theme={AlertThemeEnum.gray}
                    className={cx('alert')}
                >
                    {t('common:transport-order-details.alerts.current-temperature', {
                        min: reeferTemperatureMin,
                        max: reeferTemperatureMax,
                    })}
                </Alert>
            )}
            {transportOrderDetails?.eta && (
                <Alert
                    icon={
                        <TimeWindowIcon
                            strokeColor={StyleGuideColorsEnum.white}
                            fillColor={StyleGuideColorsEnum.transparent}
                        />
                    }
                    size={AlertSizeEnum.small}
                    theme={AlertThemeEnum.gray}
                    className={cx('alert')}
                    tooltipNode={
                        transportOrderDetails?.timestampEta ? (
                            <TooltipContent width={150} isCenter theme={TooltipContentThemeEnum.black}>
                                {t('common:transport-order-details.alerts.sync-time', {
                                    time: formatISOTime(transportOrderDetails?.timestampEta),
                                })}
                            </TooltipContent>
                        ) : null
                    }
                >
                    {t('common:transport-order-details.alerts.eta', {
                        time: formatISOTime(transportOrderDetails?.eta),
                    })}
                </Alert>
            )}
            <InfoTable
                shouldRenderIcons
                className={cx('table')}
                rows={transportOrderRows}
                isCollapsable
                testSelector="transport-order"
            />
            <InfoTable
                shouldRenderIcons
                className={cx('table')}
                rows={mileageDetailsRows}
                isCollapsable
                testSelector="transport-order"
            />
            <PriceDetails className={cx('table')} list={priceDetailList} />
            <InfoTable
                shouldRenderIcons
                className={cx('table')}
                rows={assignmentDetailsRows}
                testSelector="assignment-details"
            />
            <RouteDetailsCard className={cx('route-card')} transportOrderDetails={transportOrderDetails} />
            {transportOrderDetails?.shipments?.map((shipment, index) => {
                return (
                    <ShipmentDetailsCard
                        key={index}
                        isReadOnly={isReadOnly}
                        className={cx('shipment-card')}
                        isCurrentLastNotCanceledShipment={checkIsLastNotCanceledShipment(shipment.id)}
                        isReeferTrailer={!!transportOrderDetails?.trailerInfo?.reefer}
                        transportOrderId={transportOrderDetails?.id || null}
                        shipmentNumber={index}
                        shipment={shipment}
                        pickUpWaypoint={waypointById[shipment?.pickupPointId] || null}
                        dropOffWaypoint={waypointById[shipment?.dropOffPointId] || null}
                        keyboardShortcut={String(index + 1)}
                    />
                );
            })}
            <TourPapersCard
                className={cx('papers-card')}
                tourId={transportOrderDetails?.tourInfo?.id || null}
                isBroker={isBroker}
                title={t('common:order-details.papers')}
            />
        </div>
    );
});

export default TransportOrderDetails;
