import React from 'react';

import { CompanyContactT } from 'common/store/members/models';
import { useTranslation } from 'react-i18next';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import Modal from 'common/components/Modal/Modal';
import { PartnerTypeEnum, RolesEnum } from 'common/utils/api/models';
import BigQuestionIcon from 'common/icons/BigQuestionIcon';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import { StyleGuideColorsEnum } from 'common/constants';
import { USER_ROLE_T_MAP } from 'common/utils/t';
import { useSelector } from 'react-redux';
import { selectUpdateMemberRequest } from 'common/store/members/selectors';
import { ChangeUserRoleDataT } from '../../ChangeRoleUserForm/ChangeRoleUserForm';
import { ChangeUserRoleWithSuccessorDataT } from 'broker-admin/layouts/PartnerPage/PartnerMembersPage/ChangeRoleUserWithSuccessorForm/ChangeRoleUserWithSuccessorForm';

export type ChangeUserRoleConfirmationDataT = {
    contact: {
        roles: CompanyContactT['roles'];
        fullName: CompanyContactT['fullName'];
        userId: CompanyContactT['userId'];
    } | null;
    partnerType: PartnerTypeEnum;
    partnerId: PartnerIdT;
    isNeedSuccessor: boolean;
} & (
    | {
          isNeedSuccessor: true;
          changes: ChangeUserRoleWithSuccessorDataT;
      }
    | {
          isNeedSuccessor: false;
          changes: ChangeUserRoleDataT;
      }
);

export type ChangeUserRoleConfirmationPropsT = {
    data: ChangeUserRoleConfirmationDataT | null;
    onClose: () => void;
    onConfirm: (data: ChangeUserRoleConfirmationDataT) => void;
};

const TEST_SELECTOR = 'change-user-role-confirmation';

const CONFIRMATION_MESSAGE_T: Record<PartnerTypeEnum, string> = {
    [PartnerTypeEnum.shipper]: 'common:team-members.dialogs.change-user-role-confirmation.description',
    [PartnerTypeEnum.carrier]: 'common:team-members.dialogs.change-user-role-confirmation.description',
    [PartnerTypeEnum.broker]: 'common:team-members.dialogs.change-user-role-confirmation.description',
};

const CONFIRMATION_ADMIN_MESSAGE_T: Record<PartnerTypeEnum, string> = {
    [PartnerTypeEnum.shipper]: 'common:team-members.dialogs.change-user-role-confirmation.admin-description',
    [PartnerTypeEnum.carrier]: 'common:team-members.dialogs.change-user-role-confirmation.admin-description',
    [PartnerTypeEnum.broker]: 'common:team-members.dialogs.change-user-role-confirmation.partner-admin-description',
};

const ChangeUserRoleConfirmation: React.FC<ChangeUserRoleConfirmationPropsT> = React.memo((props) => {
    const { data, onClose, onConfirm } = props;

    const contact = data?.contact || null;
    const partnerId = data?.partnerId || null;

    const updateMemberRequest = useSelector(selectUpdateMemberRequest(partnerId));

    const { t } = useTranslation();

    if (!data) {
        return null;
    }

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    testSelector={TEST_SELECTOR}
                    icon={<BigQuestionIcon baseColor={StyleGuideColorsEnum.tomatoRed} />}
                    title={t('common:team-members.dialogs.change-user-role-confirmation.title', {
                        role: t(USER_ROLE_T_MAP[data.changes.role]).toLocaleLowerCase(),
                        fullName: contact?.fullName,
                    })}
                    message={t(
                        data.changes.role === RolesEnum.companyAdmin
                            ? CONFIRMATION_ADMIN_MESSAGE_T[data.partnerType]
                            : CONFIRMATION_MESSAGE_T[data.partnerType],
                        {
                            fullName: contact?.fullName,
                        },
                    )}
                    actions={[
                        {
                            children: t('common:error-modal.actions.cancel'),
                            theme: ButtonThemeEnum.secondary,
                            testSelector: `${TEST_SELECTOR}_cancel`,
                            isDisabled: updateMemberRequest.loading,
                            onClick: () => {
                                onClose();
                            },
                        },
                        {
                            children: t('common:error-modal.actions.assign'),
                            theme: ButtonThemeEnum.danger,
                            testSelector: `${TEST_SELECTOR}_ok`,
                            isLoading: updateMemberRequest.loading,
                            onClick: () => {
                                onConfirm(data);
                            },
                        },
                    ]}
                />
            </ModalContent>
        </Modal>
    );
});

export default ChangeUserRoleConfirmation;
